import { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import {
  ControlParameter,
  ControlParameterResponse,
  MeasuredDataResponse,
  Process,
  ProcessKpi,
  Stage,
} from '../model';
import apiService from '../services/ProcessServices';
import _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';

export const PROCESS_ID = 2;
const INTERVAL = 60000;
const PROCESS_SETTINGS = 'processSetting';

export const ProcessContext = createContext({
  isSimulation: false,
  toggleSimulation: () => {},
  processDetail: null as Process | null,
  setProcessDetail: (processDetail: Process) => {},
  processKpis: [] as ProcessKpi[],
  loading: true,
  error: null as string | null,
  handleToggleStage: (itemId: number, updatedField: Partial<Stage>) => {},
  onSimulate: (control: ControlParameter) => {},
  patchControlParameter: (tag: string, data: object) => {},
  simLoading: false,
});

const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const [isSimulation, setIsSimulation] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [simLoading, setSimLoading] = useState<boolean>(false);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [processDetail, setProcessDetail] = useState<Process>(null as any);
  const [processKpis, setProcessKpis] = useState<ProcessKpi[]>([]);
  const stateRef = useRef(processDetail);
  const controllerRef = useRef<AbortController | null>(null);
  const timeoutRef: any = useRef();

  useEffect(() => {
    const fetchProcess = async () => {
      try {
        const data = await apiService.get<Process[]>(
          '/processes?page=1&size=20',
        );
        setProcesses(data);
      } catch (err) {
        setError('Failed to fetch process');
        console.error(err);
      }
    };

    fetchProcess().then();
  }, []);

  useEffect(() => {
    if (processes.length > 0) {
      const fetchProcessDetail = async () => {
        try {
          const processDetail = await apiService.get<Process>(
            `/processes/${PROCESS_ID}`,
          );

          const processSettings = JSON.parse(
            window.localStorage.getItem(PROCESS_SETTINGS) || 'null',
          );
          processDetail.stages.forEach((stage, index) => {
            if (processSettings) {
              const stageSetting = processSettings.stages.find(
                (item: { id: number }) => item.id === stage.id,
              );
              if (stageSetting) {
                stage.enabled = stageSetting.enabled;
              }
            } else if (stage.stage_order < 4) {
              stage.enabled = true;
            }
          });
          updateData(processDetail);

          fetchDataProcessDetail(processDetail).then((result: Process) => {
            setProcessDetail(_.cloneDeep(result));
          });

          fetchProcessKpis().then();
        } catch (err) {
          setError('Failed to fetch process detail');
          console.error(err);
        }
      };

      const fetchProcessKpis = async () => {
        try {
          const kpiData = await apiService.get<ProcessKpi[]>(
            `/processes/${processes[0].id}/kpis`,
          );
          setProcessKpis(kpiData);
          setLoading(false);
        } catch (err) {
          setError('Failed to fetch process kpis');
          console.error(err);
        }
      };

      fetchProcessDetail().then();
    }
  }, [processes]);

  useEffect(() => {
    const tick = () => {
      fetchDataProcessDetail(stateRef.current).then((result: Process) => {
        // setLoading(false);
        updateData(_.cloneDeep(result));
      });
      timeoutRef.current = setTimeout(tick, INTERVAL);
    };

    if (!isSimulation) {
      tick();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isSimulation]);

  useEffect(() => {
    stateRef.current = processDetail;
  }, [processDetail]);

  const fetchDataProcessDetail = async (
    processDetail: Process,
  ): Promise<Process> => {
    if (!processDetail) {
      return {} as Process;
    }

    const getMeasuredData = async () => {
      return apiService.get<MeasuredDataResponse>(
        `/processes/${PROCESS_ID}/quality-indicators/measured`,
      );
    };
    const getForecastedData = async () => {
      return apiService.get<MeasuredDataResponse>(
        `/processes/${PROCESS_ID}/quality-indicators/forecasted`,
      );
    };

    const getOptimalData = async () => {
      return apiService.get<MeasuredDataResponse>(
        `/processes/${PROCESS_ID}/quality-indicators/optimal`,
      );
    };

    const getControlParameter = async () => {
      return apiService.get<ControlParameterResponse>(
        `/processes/${PROCESS_ID}/control-parameters/current`,
      );
    };
    const getOptimalControlParameter = async () => {
      return apiService.get<ControlParameterResponse>(
        `/processes/${PROCESS_ID}/control-parameters/optimal`,
      );
    };
    const result = { ...processDetail };

    try {
      const [
        qualityIndicatorMeasureValues,
        qualityIndicatorForecastValues,
        qualityIndicatorOptimalValues,
        controlParamValues,
        controlParamOptimalValues,
      ] = await Promise.all([
        getMeasuredData(),
        getForecastedData(),
        getOptimalData(),
        getControlParameter(),
        getOptimalControlParameter(),
      ]);
      result.stages.forEach((stage, index) => {
        stage.quality_indicators.forEach((qi) => {
          qi.measuredData =
            qualityIndicatorMeasureValues.quality_indicators.find(
              (item) => item.name === qi.tag,
            )?.data;
          qi.forecastedData =
            qualityIndicatorForecastValues.quality_indicators.find(
              (item) => item.name === qi.tag,
            )?.data;
          qi.optimalData =
            qualityIndicatorOptimalValues.quality_indicators.find(
              (item) => item.name === qi.tag,
            )?.data;
        });
        stage.control_parameters.forEach((cp) => {
          cp.value = controlParamValues.control_parameters.find(
            (item) => item.name === cp.tag,
          )?.value;
          cp.optimalValue = controlParamOptimalValues.control_parameters.find(
            (item) => item.name === cp.tag,
          )?.value;
          cp.loaded = true;
        });
      });
    } catch (error: AxiosError | any) {
      setError(error.message);
    }

    return result;
  };

  const handleToggleStage = (itemId: number, updatedField: Partial<Stage>) => {
    const newProcessDetail = {
      ...processDetail,
      stages: processDetail?.stages.map((item) =>
        item.id === itemId ? { ...item, ...updatedField } : item,
      ),
    };
    setProcessDetail(newProcessDetail);

    const processSettings = {
      stages: newProcessDetail.stages.map((item) => {
        return {
          id: item.id,
          enabled: item.enabled,
        };
      }),
    };
    window.localStorage.setItem(
      PROCESS_SETTINGS,
      JSON.stringify(processSettings),
    );
  };
  const updateData = (newData: Process) => {
    setProcessDetail(newData); // Update with a new instance
  };
  const toggleSimulation = () => {
    if (!isSimulation) {
      setIsSimulation(true);
    } else {
      if (processDetail)
        processDetail.stages.forEach((stage) => {
          if (stage)
            stage.quality_indicators.forEach((qi) => {
              if (qi.simulationData) {
                qi.simulationData = [];
              }
            });
        });
      // updateData(_.cloneDeep(processDetail));
      setIsSimulation(false);
    }
  };

  const onSimulate = (control: ControlParameter) => {
    setSimLoading(true);
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    // Create a new AbortController for the current request
    const newController = new AbortController();
    controllerRef.current = newController;

    const targetControl = findControlParamChildBytag(
      processDetail.stages,
      control.tag,
    );
    if (targetControl) {
      targetControl.simulation_value = control.simulation_value;
    }
    // updateData(processDetail);
    const allCpInputToSim = processDetail.stages
      .flatMap((stage) => stage.control_parameters)
      .map((cp) => {
        return {
          tag: cp.tag,
          value: cp.simulation_value ?? cp.value,
        };
      });
    const body: any = {};
    allCpInputToSim.forEach((cp) => {
      body[cp.tag] = cp.value;
    });

    apiService
      .post<MeasuredDataResponse, any>(
        `/processes/${PROCESS_ID}/quality-indicators/simulated`,
        body,
        newController,
      )
      .then((data: MeasuredDataResponse) => {
        setSimLoading(false);
        if (data.quality_indicators) {
          data.quality_indicators.forEach((qi, index) => {
            const cpToChange = findIndQualityByKey(
              processDetail.stages,
              qi.name,
            );
            if (cpToChange) {
              cpToChange.simulationData = qi.data;
              data.quality_indicators[index] = qi;
            }
          });
          updateData(JSON.parse(JSON.stringify(processDetail)));
        }
      })
      .catch((err) => {
        setSimLoading(false);
        console.error(err);
      });
  };

  const patchControlParameter = (tag: string, data: object) => {
    const controlParameter = findControlParamChildBytag(
      processDetail.stages,
      tag,
    );
    if (controlParameter) {
      apiService
        .patch<ControlParameter, any>(
          `/processes/${processDetail?.id}/control-parameters/${controlParameter.tag}`,
          data,
        )
        .then((response: ControlParameter) => {
          controlParameter.can_optimize = response.can_optimize;
          setProcessDetail({ ...processDetail });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <ProcessContext.Provider
      value={{
        isSimulation,
        toggleSimulation,
        processDetail,
        setProcessDetail: updateData,
        processKpis,
        loading,
        error,
        handleToggleStage,
        onSimulate,
        patchControlParameter,
        simLoading,
      }}
    >
      {children}
    </ProcessContext.Provider>
  );
};

export default DashboardProvider;
export const findControlParamChildBytag = (parents: Stage[], key: string) => {
  for (const parent of parents) {
    const foundChild = parent.control_parameters.find(
      (child) => child.tag === key,
    );
    if (foundChild) {
      return foundChild;
    }
  }
  return null;
};

export function findIndQualityByKey(parents: Stage[], key: string) {
  for (const parent of parents) {
    const foundChild = parent.quality_indicators.find(
      (child) => child.tag === key,
    );
    if (foundChild) {
      return foundChild;
    }
  }
  return null;
}

import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Range } from 'react-range';
import {
  roundNumberPad2,
  PROCESS_COLORS,
  useDebounce
} from '../utils/DataUtils';

const STEP = 0.01;
const MIN = 0;
const MAX = 100;

const Track = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingTop: '35px',
        paddingBottom: '24px',
      }}
    >
      {children}
    </div>
  );
};

const MultipleThumbs: React.FC<{
  rtl: boolean;
  min: number;
  max: number;
  current: number | undefined;
  optimal?: number | undefined;
  simulation?: number | undefined;
  isSimulation?: boolean;
  onSimulate: (simValue: number) => void;
  canOptimize?: boolean;
}> = ({
  rtl,
  min = MIN,
  max = MAX,
  current = null,
  optimal = null,
  simulation = null,
  isSimulation = false,
  onSimulate,
  canOptimize
}) => {
  const [values, setValues] = React.useState<number[]>([]);
  const [step, setStep] = React.useState<number>(STEP);

  const [stackIndexType, setStackIndexType] = useState<string[]>([]);
  const [stackColorType, setStackColorType] = useState<string[]>([]);
  const [simulationDragged, setSimulationDragged] = useState<boolean>(false);
  const trackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const stackIndexTypeValues = [];
    const stackColorTypeValues = [];
    const values: number[] = [];
    if (current && !isNaN(current)) {
      values.push(current);
      stackIndexTypeValues?.push('current');
      stackColorTypeValues?.push(PROCESS_COLORS.FORECAST);
    }
    if (optimal && !isNaN(optimal) && canOptimize) {
      values.push(optimal);
      stackIndexTypeValues?.push('optimal');
      stackColorTypeValues?.push(PROCESS_COLORS.OPTIMAL);
    }
    if (isSimulation) {
      if (current && !isNaN(current)) {
        values.push(current);
        stackIndexTypeValues?.push('simulation');
        stackColorTypeValues?.push(PROCESS_COLORS.SIMULATION);
      }
      setValues(values);
    } else {
      setValues(values);
    }
    setStackIndexType(stackIndexTypeValues);
    setStackColorType(stackColorTypeValues);
    const step = (max - min) / (trackRef.current?.offsetWidth ?? 200);
    setStep(step);
  }, [current, optimal, simulation, isSimulation, canOptimize, max, min]);

  const getHandleIndex = (type: string) => {
    return stackIndexType?.indexOf(type) ?? -1;
  };
  function emitSimulate() {
    if (isSimulation) {
      setSimulationDragged(true);
      onSimulate(values[getHandleIndex('simulation')]);
    }
  }

  const debounceFn = useDebounce(emitSimulate, 500);

  const handleInputChange = (values: number[]) => {
    setValues(values);
    debounceFn();
  };

  useEffect(() => {
    return () => {
      // if (debounceFn) debounceFn.cancel(); // Cleanup the debounce on component unmount or when values change
    };
  }, []);

  return (
    <Track>
      <Range
        allowOverlap
        values={values}
        step={step}
        min={min}
        max={max}
        rtl={rtl}
        onChange={handleInputChange}
        renderTrack={({ props, children }) => (
          <div
            // onMouseDown={props.onMouseDown}
            // onTouchStart={props.onTouchStart}
            ref={trackRef}
            style={{
              ...props.style,
              height: '3px',
              display: 'flex',
              width: '100%',
            }}
          >
            <span title={min.toString()} className={`absolute left-0 text-xs pt-1`}>{roundNumberPad2(min)}</span>

            <div
              ref={props.ref}
              style={{
                height: '3px',
                width: '100%',
                background: '#ccc',
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
            <span className={`absolute right-0 text-xs pt-1`} title={max.toString()}>
              {roundNumberPad2(max)}
            </span>
          </div>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: '0',
              width: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex:
                isSimulation && index === getHandleIndex('current') && !simulationDragged
                  ? 30
                  : index,
            }}
            title={values[index]?.toString()}
          >
            <div
              style={{
                position: 'absolute',
                top: index === getHandleIndex('optimal') ? '-40px' : 'unset',
                bottom: index !== getHandleIndex('optimal') ? '-40px' : 'unset',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                pointerEvents:
                  index !== getHandleIndex('simulation') ? 'none' : 'auto',
              }}
            >
              {index === getHandleIndex('optimal') ? (
                <svg
                  width="36"
                  height="28"
                  viewBox="0 0 36 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 9C0 4.58172 3.58172 1 8 1H28C32.4183 1 36 4.58172 36 9V17H0V9Z"
                    fill={stackColorType[index]}
                  />
                  <path
                    d="M18.0004 28L0 17L36 17L18.0004 28Z"
                    fill={stackColorType[index]}
                  />
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="black"
                    fontSize="10"
                  >
                    {roundNumberPad2(values[index])}
                  </text>
                </svg>
              ) : (
                <svg
                  width="36"
                  height="27"
                  viewBox="0 0 36 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M36 19C36 23.4183 32.4183 27 28 27L8 27C3.58172 27 0 23.4183 0 19V11L36 11V19Z"
                    fill={stackColorType[index]}
                  />
                  <path
                    d="M17.9996 0L36 11.25L0 11.25L17.9996 0Z"
                    fill={stackColorType[index]}
                  />
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="black"
                    fontSize="10"
                  >
                    {roundNumberPad2(values[index])}
                  </text>
                </svg>
              )}
            </div>
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? stackColorType[index] : '#CCC',
              }}
            />
          </div>
        )}
      />
    </Track>
  );
};

export default MultipleThumbs;

import { QualityIndicatorValueItem } from '../model';
import { useState } from 'react';

export const getRoundedHourArray = () => {
  const now = new Date();
  const currentHour = now.getHours();
  const hoursRange = 3;
  const timeSlots = [];

  // Generate rounded hours from current hour -3 to +3
  for (let i = -hoursRange; i <= hoursRange; i++) {
    const hour = currentHour + i;
    const roundedHour = (hour + 24) % 24; // Handle negative hours and wrap around
    const formattedHour = roundedHour.toString().padStart(2, '0');
    timeSlots.push(`${formattedHour}:00`);
  }

  // Replace the current hour with 'Now'
  const nowString = `${currentHour.toString().padStart(2, '0')}:00`;
  const result = timeSlots.map((time) => (time === nowString ? 'Now' : time));

  return result;
};

const findNearestTimestamp = (
  data: QualityIndicatorValueItem[],
  targetTime: string,
  now: Date,
): QualityIndicatorValueItem | null => {
  const [hours, minutes] = targetTime.split(':').map(Number);
  const targetDate = new Date(now);
  targetDate.setHours(hours, minutes, 0, 0);

  let nearest: QualityIndicatorValueItem | null = null;
  let minDiff = Infinity;

  for (const item of data) {
    const itemDate = new Date(item.timestamp);
    const diff = Math.abs(targetDate.getTime() - itemDate.getTime());

    if (diff < minDiff) {
      minDiff = diff;
      nearest = item;
    }
  }

  return nearest;
};

export const getNearestTimestamps = (
  data: QualityIndicatorValueItem[],
  times: string[],
): {
  time: string;
  nearest: QualityIndicatorValueItem | null;
}[] => {
  const now = new Date();
  if (!data || !data.length) {
    return [];
  }

  return times.map((time) => {
    if (time === 'Now') {
      const nowTime = now.toISOString().substr(11, 5);
      return { time, nearest: findNearestTimestamp(data, nowTime, now) };
    } else {
      return { time, nearest: findNearestTimestamp(data, time, now) };
    }
  });
};

export const getCssVariable = (name: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(name);
};
document.addEventListener('DOMContentLoaded', () => {
  PROCESS_COLORS = {
    SIMULATION: getCssVariable('--brand-4th-primary'),
    FORECAST: getCssVariable('--brand-yellow-primary'),
    ACTUAL: getCssVariable('--light-3'),
    OPTIMAL: getCssVariable('--brand-green-primary'),
    PAST_PREDICT: getCssVariable('--light-3'),
  };
});
export let PROCESS_COLORS: any = {};

export const roundNumberPad2 = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const INTERVAL = 60000;

export const useDebounce = (func: any, delay: any) => {
  const [timer, setTimer] = useState(null);

  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer: any = setTimeout(() => {
      func(...args);
    }, delay);
    setTimer(newTimer);
  };
};

export function convertMinutes(milisecond: number) {
  let minutes = milisecond / 60000;
  const sign = minutes < 0 ? '-' : '+'; // Dấu âm nếu minutes là số âm
  minutes = Math.abs(minutes); // Bỏ dấu âm để tính toán

  if(Math.round(minutes) === 0) {
    return 'NOW'
  }
  if (minutes < 60) {
    return `${sign}${Math.round(minutes)}m`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return `${sign}${hours}h${remainingMinutes}m`;
  }
}

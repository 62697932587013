import KpiChart from '../../components/KpiChart';
import StageLine from '../../components/StageLine';
import React, { Suspense, useEffect, useState } from 'react';
import { ProcessKpi, Stage } from '../../model';
import '../../styles/_variables.scss';
import './dashboard.scss';
import ProcessToolbar from '../../components/ProcessToolbar';
import ProcessMiniMap from '../../components/ProcessMiniMap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DashboardProvider from '../../components/DashboardProvider';
import useProcess from '../../hooks/ProcessHook';
import Loading from '../../components/loading';

const Dashboard = () => {
  const { processDetail, processKpis, error, loading, handleToggleStage } =
    useProcess();

  const [maxIndicator, setMaxIndicator] = useState(0);

  useEffect(() => {
    if (processDetail?.stages) {
      let max = 0;
      processDetail.stages.forEach((stage: Stage) => {
        if (stage.quality_indicators.length > max) {
          max = stage.quality_indicators.length;
        }
      });
      setMaxIndicator(max);
    }
  }, [processDetail]);

  if (error) {
    return (
      <div
        className={`text-center absolute w-full h-full flex flex-col items-center justify-center`}
      >
        <div>
          <span className={`text-3xl `}>
            Error occurs during the initial page load
          </span>
        </div>
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-3"
          onClick={() => {
            window.location.reload();
          }}
        >
          Try again
        </button>

      </div>
    );
  }

  //loading
  if (loading) {
    return (
      <div
        className={`flex absolute w-full h-full justify-center items-center`}
      >
        <Loading></Loading>
      </div>
    );
  } else {
    if (error) {
      return <div>{error}</div>;
    }
    return (
      <div className={`min-h-screen bg-black relative`}>
        <ProcessToolbar></ProcessToolbar>
        <div className={`pt-6 p-4 flex gap-6`}>
          {processKpis.map((kpi: ProcessKpi, index: number) => {
            return (
              <div
                className={`min-w-56 rounded-xl p-2 bg-dark-3`}
                key={`kpi_chart${index}`}
              >
                <KpiChart data={kpi}></KpiChart>
              </div>
            );
          })}
        </div>
        <div className={`px-6 mt-12`}>
          <ProcessMiniMap
            process={processDetail}
            updateData={handleToggleStage}
          ></ProcessMiniMap>
        </div>
        <TransitionGroup className={` overflow-x-auto whitespace-nowrap`}>
          {processDetail?.stages &&
            processDetail?.stages
              .filter((stage: Stage) => stage.enabled)
              .sort((a, b) => a.stage_order - b.stage_order)
              .map((stage: Stage) => {
                return (
                  <CSSTransition
                    key={stage.tag}
                    timeout={200}
                    classNames="item"
                  >
                    <Suspense key={stage.id}>
                      <StageLine
                        stageData={stage}
                        fillIndicators={
                          maxIndicator - stage.quality_indicators.length
                        }
                        lastStage={false}
                      ></StageLine>
                    </Suspense>
                  </CSSTransition>
                );
              })}
        </TransitionGroup>
      </div>
    );
  }
};

const DashboardWrapper = () => {
  return (
    <DashboardProvider>
      <Dashboard></Dashboard>
    </DashboardProvider>
  );
};

export default DashboardWrapper;

import StageLineChart from './StageLineChart';
import StageSlider from './StageSlider';
import { ControlParameter, Stage } from '../model';
import styles from './stageline.module.scss';
import useProcess from '../hooks/ProcessHook';

const StageLine = ({
  stageData,
  lastStage,
  fillIndicators = 0,
}: {
  stageData: Stage;
  lastStage?: boolean;
  fillIndicators: number;
}) => {
  const { simLoading } = useProcess();
  return (
    <div
      className={`${styles.StageWraper} ${lastStage ? styles.lastStage : null}`}
    >
      <div className={`mt-8 pb-24 ${styles.stageContent} `}>
        {stageData.enabled && (
          <div>
            <div className={`text-white`}>
              {stageData.name} Stage
            </div>
            <div className={`mt-4 grid-cols-1 grid gap-3`}>
              {!stageData.quality_indicators.length ? (
                <div className={`text-white`}>No quality indicators</div>
              ) : null}
              {stageData.quality_indicators.map((indicator) => {
                return (
                  <StageLineChart
                    key={indicator.tag}
                    childKey={indicator.tag}
                    indicatorData={indicator}
                    forecastStepSeconds={stageData.forecast_step_seconds}
                    simLoading={simLoading}
                  ></StageLineChart>
                );
              })}
              {
                fillIndicators>0 &&
                Array.from({ length: fillIndicators }, (_, index) => index + 1)
                  .map((_, index) => {
                    return (
                      <StageLineChart
                        key={`hiddenChart${index}`}
                        childKey={`hiddenChart${index}`}
                        indicatorData={stageData.quality_indicators[0]}
                        hidden={true}
                        forecastStepSeconds={stageData.forecast_step_seconds}
                        simLoading={simLoading}
                      ></StageLineChart>
                    );
                  })
              }
            </div>
            {stageData.control_parameters.length > 0 ? (
              <>
                <div className={`text-white border-b-2 mt-12 flex`}>
                  {stageData.name}
                </div>
                <div className={`mt-4 grid grid-cols-2 gap-4`}>
                  {stageData.control_parameters.map(
                    (control: ControlParameter, index) => {
                      return (
                        <StageSlider
                          key={control.tag + index}
                          config={control}
                        ></StageSlider>
                      );
                    },
                  )}
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default StageLine;

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import './global.scss';
import DashboardWrapper from './pages/dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from './components/ErrorPage';

const router = createBrowserRouter([
  {
    path: '/',
    Component: DashboardWrapper,
    errorElement: <ErrorPage></ErrorPage>
  },
]);

function App() {
  return (
    <>
      <ToastContainer></ToastContainer>
      <div className={`h-screen flex`}>
        <div className={`bg-gray-900 w-12 flex flex-col p-2`}>
          <Cog8ToothIcon
            className={`text-white mt-auto cursor-pointer`}
          ></Cog8ToothIcon>
        </div>
        <div className={`h-full overflow-auto flex-1`}>
          <RouterProvider router={router} />
        </div>
      </div>
    </>
  );
}

export default App;

import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  const error: any = useRouteError();
  return (
    <div className={`absolute w-full h-full flex items-center justify-center`}>
      <div>
        <h1  className={`text-3xl font-bold`}>Oops!</h1>
        <p className={`mt-4`}>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
        <a href={'/'} className={`text-cyan-600 mt-2`}>Go to Home</a>
      </div>
    </div>
  );
}
export default ErrorPage;
